/* eslint-disable */

var player;

const openPopup = (videoUrl, videoName) => {
    const $popup = $("#teaser-popup");
    const $videoFrame = $("#video-frame");
    const $videoNameElement = $(".teaser-popup-name");

    $videoFrame.attr("src", videoUrl);

    $videoNameElement.text(videoName);
    $popup.addClass("is-active");

    if (!player) {
        player = new Vimeo.Player('video-frame');
        player.on('ended', closePopup);
    } else {
        player.loadVideo(videoUrl);
    }
};

const closePopup = () => {
    const $popup = $("#teaser-popup");
    const $videoFrame = $("#video-frame");

    $videoFrame.attr("src", ""); // stop video
    $popup.removeClass("is-active");

    if (player) {
        player.unload();
        player.off('ended', closePopup);
        player = null;
    }
};

const initPopup = () => {
    const $buttons = $(".js-teaser-btn");
    const $closeButton = $(".teaser-popup-close");
    const $overlay = $(".teaser-popup");

    $buttons.on("click", function () {
        const videoUrl = $(this).data("video-url");
        const videoName = $(this).data("video-name") || "";
        openPopup(videoUrl, videoName);
    });

    $closeButton.on("click", closePopup);
    $overlay.on("click", closePopup);
};

export default initPopup;